import React from 'react'
import { FormGroup, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'

import OnboardingStepper from '../../components/onboarding/Stepper'
import { PLATFORMS_ARRAY } from '../../constants'
import Select from '../../components/select'

const EligibilityGateContent = ({ handleSubmit, onSubmit, control }) => {
  return (
    <div className="eligibilityGateContent">
      <OnboardingStepper />
      <Typography variant="body1">
        Before you get started we need a little more info to give you the best experience.
      </Typography>
      <form className="eligibilityGateForm" onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Controller
            control={control}
            name="platform"
            rules={{
              required: {
                value: true,
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Select currentItem={value} options={PLATFORMS_ARRAY} onChange={onChange} />
            )}
          />
        </FormGroup>
      </form>
    </div>
  )
}

export default EligibilityGateContent
