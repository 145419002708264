import React from 'react'
import { Container } from '@mui/system'
import { Typography } from '@mui/material'
import GiftCardsRow from './components/GiftCardsRow'

const Content = ({ giftCards, minRedeem = 0 }) => {
  return (
    <div className="giftCardList">
      <Container maxWidth="sm" sx={styles.titleContainer}>
        <div className="giftCardListTitlesInner">
          <Typography variant="h1">Redeem Rewards</Typography>
          <Typography
            variant="h4"
            sx={{
              ...styles.giftCardsText,
              ...(!minRedeem && styles.padding20),
            }}
          >
            Spend <img src="/images/pig.png" /> & <img src="/images/coin.png" /> to redeem gift
            cards!
          </Typography>
          {Boolean(minRedeem) && (
            <Typography
              variant="h4"
              sx={{
                ...styles.minimumRedemption,
                ...(minRedeem && styles.padding20),
              }}
            >
              Minimum ${minRedeem} first redemption
            </Typography>
          )}
        </div>
      </Container>

      <Container maxWidth="sm" sx={styles.giftCardsContainer}>
        <div className="giftCardListInner">
          {giftCards?.map((giftCard, index) => (
            <GiftCardsRow key={index} giftCard={giftCard} minRedeem={minRedeem} />
          ))}
        </div>
      </Container>
    </div>
  )
}

const styles = {
  titleContainer: {
    padding: '0',
  },
  giftCardsContainer: {
    padding: 0,
  },
  giftCardsText: {
    padding: '8px 0',
    fontWeight: 400,
    color: '#000000',
  },
  padding20: {
    paddingBottom: '20px',
  },
  minimumRedemption: {
    fontWeight: 400,
    color: '#DC2626',
  },
}

export default Content
