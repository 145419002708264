import { v5 as uuidv5 } from 'uuid'
import { axiosBackendInstance } from '../axios'

export const getUserGameDetailsByAppId = async (unifiedAppId, userId) => {
  return axiosBackendInstance.get(`/api/v1/games/${unifiedAppId}/${userId}`)
}

//Favorite games endpoint
export const getGameLibraryByUserId = async (userId) => {
  return axiosBackendInstance.get(`/api/v1/users/${userId}/library`)
}

export const activateOffer = async (offer) => {
  return axiosBackendInstance.post(`/api/v1/offers/activate`, offer)
}

export const getAllFeaturedGameOffers = async () => {
  return axiosBackendInstance.get('/api/v1/offers')
}

export const generateClickId = (userId, campaignId) => {
  let seed = ''

  if (!campaignId) {
    seed = userId
  } else {
    seed = userId + campaignId
  }

  const CLICK_ID_NAMESPACE = '960d76e1-00ac-4a5a-a30d-e6d4d72f09c9'

  return uuidv5(seed, CLICK_ID_NAMESPACE)
}
