import React from 'react'
import { Typography } from '@mui/material'

import { Clock } from '../../utils/icons'

import './index.css'

const Timer = ({
  isActive,
  time,
  customClassName = '',
  expired = false,
  showExpiration = true,
  title,
}) => {
  if (!showExpiration) {
    return <></>
  }

  return (
    <div
      className={`timer ${isActive ? 'active' : ''} ${expired ? 'expired' : ''} ${customClassName}`}
    >
      <Typography variant="body2" sx={styles.text}>
        {expired ? (
          'Offer ended'
        ) : (
          <>
            {title} <Clock /> {time}
          </>
        )}
      </Typography>
    </div>
  )
}

const styles = {
  text: {
    color: '#ffffff',
    fontWeight: 500,
  },
}

export default Timer
