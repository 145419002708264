import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { Box, Container, Typography } from '@mui/material'
import { captureEvent } from '@sentry/react'
import _ from 'lodash'

//hooks
import useQueryGeoData from 'hooks/useQueryGeoData'
//others
import AuthContext from '../../context/AuthContext'
import NotFound from '../NotFound'
import useLoader from '../../hooks/useLoader'
import BottomButton from '../../components/common/BottomButton'
import {
  getUserGameDetailsByAppId,
  activateOffer,
  generateClickId,
} from '../../services/offersService'
import { buildMMPClickDetails, saveClickId } from '../../services/mmpService'
import { BackArrow as ArrowIcon } from '../../utils/icons'
import {
  ANALYTICS_EVENT,
  ANALYTICS_OFFER_ENGAGEMENT_TYPE,
  CLAIM_SPECIAL_REWARD_REASON,
  ERRORS,
  GAME_DETAILS_BUTTON_TEXT,
  LOCAL_STORAGE,
  OFFER_TYPE,
  ONBOARDING_STEP,
  REACT_NATIVE_STATUS,
  USER_PLATFORM,
} from '../../constants'
import {
  checkOfferValidating,
  getUserPlatform,
  openGameLink,
  sendGA4Event,
  sendRNMessage,
} from '../../utils/utils'
import {
  claimAggregatedReward,
  claimSpecialReward,
  createSpecialReward,
} from '../../services/userRewardsService'
import Rating from '../../components/rating'
import Modal from '../../components/modal'
import RewardHistory from '../../components/rewardHistory'
import useLocalStorage from '../../hooks/useLocalStorage'
import ImageCrop from '../../components/ImageCrop'
import { updateQuestPlayTimeProgress } from '../../services/questsService'
import useOfferInstallValidation from '../../hooks/useOfferInstallValidation'
import SpecialOffer from './components/SpecialOffer'
import OfferContent from './components/OfferContent'
import BackArrow from './components/BackArrow'
import Media from './components/Media'
import DownloadConfirmationModalContent from './components/SpecialOffer/components/ModalContent/DownloadConfirmation'
import IosAccessModalContent from './components/SpecialOffer/components/ModalContent/IosAccess'

import './index.css'

const MIN_IMAGE_HEIGHT = 73
const MAX_IMAGE_HEIGHT = 269
const IMAGE_WIDTH = 600

const FINAL_ONBOARDING_STEPS = [
  ONBOARDING_STEP.DOWNLOAD_CONFIRMATION,
  ONBOARDING_STEP.OFFER_REWARDS_EXPLAINER,
]

const GameDetails = () => {
  const { user, changeStep, onboardingStep, updateUserData } = useContext(AuthContext)

  const [validating, setValidating] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalProps, setModalProps] = useState([])
  const [imageHeight, setImageHeight] = useState(MAX_IMAGE_HEIGHT)
  const [descriptionExpanded, setDescriptionExpanded] = useState(false)
  const [bounced, setBounced] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [eventData, setEventData] = useState({})
  const [isDisabled, setDisabled] = useState(false)

  const { loader, showLoader, hideLoader } = useLoader(true)

  const headerRef = useRef(null)

  const queryClient = useQueryClient()
  const { getItem, setItem, removeItem } = useLocalStorage()

  const navigate = useNavigate()
  const { unifiedAppId } = useParams()

  const isArrowDisabled = onboardingStep?.name === ONBOARDING_STEP.ACTIVATE_OFFER

  const handleClickDescriptionArrow = () =>
    !isArrowDisabled && setDescriptionExpanded((current) => !current)

  const hideModal = () => setModal(false)

  const showModal = (modalProps) => {
    setModalProps(modalProps)
    setModal(true)
  }
  const handleScroll = () => {
    setImageHeight(Math.max(MIN_IMAGE_HEIGHT, MAX_IMAGE_HEIGHT - window.scrollY))
  }

  /**
   * A function to change the onboarding step forward or backward and by a different number of steps depending on the received parameters
   *
   * @function
   * @param {boolean} next - checks forward or backward step changes
   * @param {number} steps - the value by which the current step changes
   * @returns {void}
   */
  const handleChangeOnboardingStep = async (next = true, steps = 1) => {
    setDisabled(true)
    await changeStep(next, steps).finally(() => setDisabled(false))
  }

  // Query hooks
  const { data: game, isFetching: isFetching } = useQuery(
    ['gameDetails', unifiedAppId, user.id],
    () => getUserGameDetailsByAppId(unifiedAppId, user.id),
    {
      retry: false,
      onSuccess: async (data) => {
        const { activatedOffer, specialOfferDetails } = data
        if (specialOfferDetails) {
          setEventData({ offer_campaign_id: specialOfferDetails.offerCampaignId })
        }

        let isValidating = !!activatedOffer
        if (activatedOffer) {
          isValidating = checkOfferValidating(activatedOffer, specialOfferDetails)
        }

        setValidating(isValidating)
      },
    }
  )
  const { geoData } = useQueryGeoData()

  const specialOffer = useMemo(() => game?.specialOfferDetails, [game])
  const gameName = useMemo(
    () => specialOffer?.gameName || game?.gameDetails?.name || '',
    [specialOffer, game]
  )
  const iconUrl = useMemo(
    () => specialOffer?.iconUrl || game?.gameDetails?.icon_url,
    [specialOffer, game]
  )
  const featuredGraphicUrl = useMemo(
    () =>
      specialOffer?.featuredGraphic ||
      game?.gameDetails?.feature_graphic ||
      game?.gameDetails?.screenshot_urls[0],
    [specialOffer, game]
  )
  const videoUrl = useMemo(() => specialOffer?.videoUrl || null, [specialOffer])
  const appDescription = useMemo(() => specialOffer?.appDescription || '', [specialOffer])

  useEffect(() => {
    setBounced(!loader && !window.scrollY)
  }, [loader])

  useEffect(() => {
    if (isFetching) {
      return showLoader()
    }

    hideLoader()
    handleScroll()
  }, [isFetching])

  useEffect(() => {
    if (unifiedAppId === 'undefined') {
      captureEvent({
        message: ERRORS.UNIFIED_APP_ID_UNDEFINED,
        level: 'error',
      })
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (onboardingStep) {
      const stepProps = getOnboardingModalProps(onboardingStep.name, offer)

      if (stepProps) {
        handleOnboardingModalShow(
          stepProps,
          onboardingStep.name === ONBOARDING_STEP.DOWNLOAD_CONFIRMATION
        )
      }
    }
  }, [onboardingStep])

  useEffect(() => {
    const openMMP = sessionStorage.getItem(LOCAL_STORAGE.OPEN_MMP_LINK)

    if (game && openMMP) {
      sessionStorage.removeItem(LOCAL_STORAGE.OPEN_MMP_LINK)
      handleBottomButtonClick()
    }
  }, [game])

  const handlePlayGame = async (clickId, saveClickDetails = true) => {
    // Assuming this is generated ahead of time but there was a default value assigned for clickId so I am handling that use case
    if (!clickId) {
      clickId = await generateClickId(user.id, game.specialOfferDetails?.id)
    }

    showLoader()

    let clickableLink = game.gameDetails?.url

    if (game.activatedOffer?.isInstallAttributed && game.specialOfferDetails) {
      clickableLink = game.specialOfferDetails.storeLink
      // Leaving these comments in for now for debugging purposes on release. Can remove after verified
      console.log('Using clickableLink from campaign', clickableLink)
    } else {
      const mmpClickDetails = await buildMMPClickDetails(
        clickId,
        user,
        game,
        user.IPAddress?.lastLogin || geoData?.ip || ''
      )

      clickableLink = mmpClickDetails.clickedLink
      // Leaving these comments in for now for debugging purposes on release. Can remove after verified
      console.log('Using clickableLink from MMP generation', clickableLink)
      if (saveClickDetails) {
        await saveClickId(mmpClickDetails)
      }
    }

    if (game.activatedOffer) {
      await updateQuestPlayTimeProgress(unifiedAppId)
    }

    if (
      game.specialOfferDetails?.offerType === OFFER_TYPE.REWARDED_PLAYTIME ||
      game.specialOfferDetails?.batchPlaytimeConfig
    ) {
      sendRNMessage({
        status: REACT_NATIVE_STATUS.START_PLAYTIME_TRACKING_IN_FOREGROUND,
      })
    }

    if (game.specialOfferDetails?.offerType === OFFER_TYPE.MMP_EVENTS) {
      const lastClickedMMPEventTask = getItem(LOCAL_STORAGE.LAST_CLICKED_MMP_EVENT_TASK)
      if (lastClickedMMPEventTask) {
        handleAnalyticsEvent(ANALYTICS_OFFER_ENGAGEMENT_TYPE.MMP_EVENTS, lastClickedMMPEventTask)
        removeItem(LOCAL_STORAGE.LAST_CLICKED_MMP_EVENT_TASK)
      }
    }

    if (FINAL_ONBOARDING_STEPS.indexOf(user.onboardingStep) > -1) {
      setItem(
        LOCAL_STORAGE.ONBOARDING_MMP,
        JSON.stringify({
          mmpLink: clickableLink,
          gameDetails: {
            id: game.gameDetails.id,
            url: game.gameDetails.url,
            unified_app_id: game.gameDetails.unified_app_id,
          },
        })
      )

      removeItem(LOCAL_STORAGE.OFFER_REWARDS_EXPLAINER_GAME)
      await handleChangeOnboardingStep()

      return navigate('/quests')
    }

    openGameLink(clickableLink, user, game.gameDetails)
    hideLoader()
  }

  const handleClaimUnclaimedRewards = async () => {
    showLoader()

    claimAggregatedReward(game.activatedOffer.id)
      .then(({ updatedUser }) => {
        updateUserData(updatedUser)
        queryClient.invalidateQueries({ queryKey: 'gameDetails' })
      })
      .finally(() => hideLoader())
  }

  const handleAnalyticsEvent = (type, day) => {
    sendGA4Event(ANALYTICS_EVENT.OFFER_ENGAGEMENT, {
      event_type: day ? type(day) : type,
      offer_id: _.get(game, 'specialOfferDetails.id', ''),
      ...eventData,
    })
  }

  const handleOnboardingModalHide = () => {
    if (user.onboardingStep !== ONBOARDING_STEP.COMPLETE) {
      handleChangeOnboardingStep(false, 2)
    }

    hideModal()
  }

  const handleOnboardingModalShow = (props, downloadConfirmation = true) => {
    showModal({
      titleVariant: 'title',
      isCustomText: true,
      closeButton: true,
      actionButtons: (
        <BottomButton handleClick={() => handleOnboardingModalClick(downloadConfirmation)}>
          {props.buttonText}
        </BottomButton>
      ),
      hideModal: handleOnboardingModalHide,
      ...props,
    })
  }

  const handleOnboardingModalClick = async (downloadConfirmation = true) => {
    hideModal()

    setValidating(downloadConfirmation)

    if (!downloadConfirmation) {
      await handleChangeOnboardingStep()

      return handleOnboardingModalShow(
        getOnboardingModalProps(ONBOARDING_STEP.DOWNLOAD_CONFIRMATION, offer)
      )
    }

    handleAnalyticsEvent(ANALYTICS_OFFER_ENGAGEMENT_TYPE.TRY)
    sendRNMessage({
      status: REACT_NATIVE_STATUS.SEND_EVENT_APPSFLYER,
      eventName: 'try_game',
      eventValue: {
        offerId: _.get(game, 'specialOfferDetails.id', ''),
        time: Date.now(),
        ...eventData,
      },
    })

    const clickId = generateClickId(user.id, game.specialOfferDetails?.id)
    await handleActivateOffer(clickId)
    handlePlayGame(clickId, false)
  }

  const handleTryGame = async () => {
    if (
      user.onboardingStep &&
      [ONBOARDING_STEP.GAMES_EXPLAINER, ONBOARDING_STEP.TRY_GAME].includes(user.onboardingStep)
    ) {
      await handleChangeOnboardingStep(
        true,
        user.onboardingStep === ONBOARDING_STEP.GAMES_EXPLAINER ? 2 : 1
      )
    }

    const downloadConfirmation = user.platform !== USER_PLATFORM.IOS

    handleOnboardingModalShow(
      getOnboardingModalProps(
        downloadConfirmation ? ONBOARDING_STEP.DOWNLOAD_CONFIRMATION : ONBOARDING_STEP.IOS_ACCESS,
        offer
      ),
      downloadConfirmation
    )
  }

  const handleActivateOffer = async (clickId) => {
    try {
      showLoader()
      const mmpClickDetails = await buildMMPClickDetails(
        clickId,
        user,
        game,
        user.IPAddress?.lastLogin || geoData?.ip || ''
      )
      await activateOffer({
        campaignId: game.specialOfferDetails.offerCampaignId,
        offerListItemId: game.specialOfferDetails.id,
        clientPlatform: getUserPlatform(),
        mmpClickDetails,
      })

      sendRNMessage({ status: REACT_NATIVE_STATUS.FB_EVENT_INSTALL_GAME, userId: user.id })
      queryClient.invalidateQueries({ queryKey: 'gameDetails' })
      updateQuestPlayTimeProgress(unifiedAppId)
    } catch (err) {
      console.error(err)
    } finally {
      hideLoader()
    }
  }

  const offer = game?.activatedOffer || game?.specialOfferDetails

  const getOnboardingModalProps = (step, offer) => {
    switch (step) {
      case ONBOARDING_STEP.IOS_ACCESS: {
        return {
          modal: true,
          title: 'Ensure you earn  rewards',
          text: <IosAccessModalContent />,
          buttonText: 'WILL DO!',
          path: '/games/:unifiedAppId',
        }
      }
      case ONBOARDING_STEP.DOWNLOAD_CONFIRMATION: {
        if (offer?.playtimeRewards) {
          return {
            modal: true,
            title: 'Woohoo!',
            text: (
              <DownloadConfirmationModalContent
                isPlaytime={true}
                minutes={offer.playtimeRewards[0].minutes}
              />
            ),
            buttonText: 'I’m ready to download!',
            path: '/games/:unifiedAppId',
          }
        } else {
          return {
            modal: true,
            title: 'Woohoo!',
            text: <DownloadConfirmationModalContent />,
            buttonText: 'I’m ready to download!',
            path: '/games/:unifiedAppId',
          }
        }
      }
    }

    return null
  }

  useOfferInstallValidation(validating, offer, ['gameDetails', unifiedAppId, user.id])

  const handleItemReward = async (
    reason = CLAIM_SPECIAL_REWARD_REASON.PLAY_GAME,
    day = null,
    isClaim = false,
    offerSubId = null
  ) => {
    showLoader()
    const func = isClaim ? claimSpecialReward : createSpecialReward

    await func(offer.id, {
      reason,
      ...(day && { day }),
      ...(offerSubId && { offerSubId }),
    })
      .then(({ updatedUser }) => {
        if (isClaim && updatedUser) {
          updateUserData(updatedUser)
        }

        if (day && !isClaim) {
          handleAnalyticsEvent(ANALYTICS_OFFER_ENGAGEMENT_TYPE.PLAY, day)
        }
      })
      .finally(() => {
        queryClient.invalidateQueries({ queryKey: 'gameDetails' })
      })
  }

  const handleClickPlay = async () => {
    if (!game?.activatedOffer && !validating) {
      return handleTryGame()
    }

    if (offer.dayXRewards) {
      const nowSeconds = new Date().getTime() / 1000
      const unclaimedReward = offer.dayXRewards.find(
        (day) =>
          !day.claimed && day.endDate?._seconds > nowSeconds && day.startDate?._seconds < nowSeconds
      )
      if (unclaimedReward) {
        await handleItemReward(CLAIM_SPECIAL_REWARD_REASON.PLAY_GAME, unclaimedReward.day)
      }
    }
    const clickId = generateClickId(user.id, game.specialOfferDetails?.id)
    await handlePlayGame(clickId)
  }

  const handleInstallGame = async () => {
    const clickId = generateClickId(user.id, game.specialOfferDetails?.id)

    if (!game.activatedOffer && game?.specialOfferDetails) {
      await handleActivateOffer(clickId)
      handleAnalyticsEvent(ANALYTICS_OFFER_ENGAGEMENT_TYPE.ACTIVATE)

      if (onboardingStep?.name === ONBOARDING_STEP.OFFER_REWARDS_EXPLAINER) {
        return
      }
    }

    if (onboardingStep?.name === ONBOARDING_STEP.ACTIVATE_OFFER) {
      handleChangeOnboardingStep()
      return setItem(LOCAL_STORAGE.OFFER_REWARDS_EXPLAINER_GAME, game?.gameDetails?.name)
    }

    await handlePlayGame(clickId, false)
  }

  const handleBottomButtonClick = game?.specialOfferDetails?.isUAOffer
    ? handleClickPlay
    : handleInstallGame

  if (!game?.gameDetails && !specialOffer && !loader) {
    return <NotFound />
  }

  return (
    <div className={`gameDetailsWrapper ${isDisabled ? 'disabled' : ''}`}>
      {loader}
      <ImageCrop url={featuredGraphicUrl} width={IMAGE_WIDTH} setImageUrl={setImageUrl} />
      <Modal showModal={modal} hideModal={hideModal} {...modalProps} />
      <div
        className={`gameDetailsInner ${loader ? 'blured' : bounced ? 'bounce' : ''}`}
        style={{ backgroundImage: "url('/images/background.png')" }}
      >
        <div>
          <Container maxWidth="sm" sx={styles.container}>
            <div className="gameDetailsContainerInner">
              <BackArrow />
              <Media
                videoSrc={videoUrl}
                imageSrc={imageUrl}
                height={imageHeight}
                storageKey={'video-' + _.get(game, 'specialOfferDetails.id', '')}
              />
              <div
                className={`gameDetailsHeadInner ${
                  imageHeight === MIN_IMAGE_HEIGHT ? 'fixed' : ''
                }`}
              >
                <div className="gameDetailsHeadContent">
                  <div
                    className={`gameDetailsHead ${!game?.specialOfferDetails ? 'noOffer' : ''}`}
                    ref={headerRef}
                  >
                    {iconUrl && (
                      <div className="gameDetailsHeadImageWrap">
                        <img src={iconUrl} className="gameDetailsHeadImage" />
                      </div>
                    )}
                    <Box sx={styles.headContent}>
                      <Typography variant="inherit">{gameName}</Typography>
                    </Box>
                  </div>
                </div>
              </div>
              <div className={`gameDetailsContent ${!bounced && !loader ? 'bounce' : ''}`}>
                {specialOffer && (
                  <div className="gameDetailsGameInfo">
                    <Box sx={styles.gameInfoHead}>
                      <Typography variant="h3">Game Info</Typography>
                      <Rating offer={specialOffer} />
                    </Box>
                    <Typography
                      variant="body2"
                      className="gameDetailsDescription"
                      sx={styles.description(descriptionExpanded)}
                      dangerouslySetInnerHTML={{ __html: appDescription }}
                    />
                    <ArrowIcon
                      customClassName={`gameDetailsDescriptionArrow ${
                        descriptionExpanded ? 'expanded' : ''
                      } ${isArrowDisabled ? 'disabled' : ''}`}
                      onClick={handleClickDescriptionArrow}
                    />
                    {!descriptionExpanded && <div className="gameDetailsGameInfoShadow" />}
                  </div>
                )}
                <Offer
                  game={game}
                  offer={offer}
                  handleItemReward={handleItemReward}
                  handleActivateOffer={handleActivateOffer}
                  handleTryGame={handleTryGame}
                  handleInstallGame={handleInstallGame}
                  validating={validating}
                  setValidating={setValidating}
                  handlePlayGame={handlePlayGame}
                  showLoader={showLoader}
                  hideLoader={hideLoader}
                  handleAnalyticsEvent={handleAnalyticsEvent}
                  isLoading={isFetching}
                  handleClaimUnclaimedRewards={handleClaimUnclaimedRewards}
                  showModal={showModal}
                />
                <RewardHistory rewards={game?.rewards} />
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className="gameDetailsButtonInner">
        <Container maxWidth="sm" sx={styles.container}>
          <Box sx={styles.contentFooter}>
            <BottomButton handleClick={handleBottomButtonClick} style={styles.button}>
              {GAME_DETAILS_BUTTON_TEXT}
            </BottomButton>
          </Box>
        </Container>
      </div>
    </div>
  )
}

const styles = {
  container: {
    padding: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  contentFooter: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  headContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '100%',
  },
  gameInfoHead: {
    display: 'flex',
    gap: '28px',
    marginBottom: '10px',
  },
  button: {
    padding: '16px 10px',
    fontWeight: 700,
  },
  description: (expanded) =>
    expanded
      ? {}
      : {
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitLineClamp: 3,
          lineClamp: 3,
          WebkitBoxOrient: 'vertical',
        },
}

const Offer = ({
  game,
  offer,
  children,
  handleActivateOffer,
  handleTryGame,
  handleInstallGame,
  validating,
  setValidating,
  handlePlayGame,
  showLoader,
  hideLoader,
  handleAnalyticsEvent,
  isLoading,
  handleClaimUnclaimedRewards,
  handleItemReward,
  showModal,
}) => {
  if (!game?.specialOfferDetails) {
    return (
      <OfferContent title="Coin Details" gameName={game?.gameDetails?.name} coinsValue={100}>
        {children}
      </OfferContent>
    )
  }

  return (
    <SpecialOffer
      offer={offer}
      handleClaimUnclaimedRewards={handleClaimUnclaimedRewards}
      handleAnalyticsEvent={handleAnalyticsEvent}
      handleTryGame={handleTryGame}
      game={game}
      isActivated={!!game?.activatedOffer}
      handleActivateOffer={handleActivateOffer}
      handleInstallGame={handleInstallGame}
      validating={validating}
      setValidating={setValidating}
      handlePlayGame={handlePlayGame}
      showLoader={showLoader}
      hideLoader={hideLoader}
      isLoading={isLoading}
      handleItemReward={handleItemReward}
      showModal={showModal}
    >
      {children}
    </SpecialOffer>
  )
}

export default GameDetails
