import Intercom, { show, shutdown, update } from '@intercom/messenger-js-sdk'
import { INTERCOM_OPEN, INTERCOM_UPDATE, REACT_NATIVE_STATUS } from '../constants'

import { useEffect, useState } from 'react'
import { User } from 'types'
import { sendRNMessage } from 'utils/utils'
import { useLocation } from 'react-router-dom'

const useIntercom = (user: User | null) => {
  const [isNativeIntercomEnabled, setIsNativeIntercomEnabled] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [intercomUser, setIntercomUser] = useState(user)
  const [prevLocation, setPrevLocation] = useState<null | string>(null)

  const location = useLocation()

  useEffect(() => {
    // We ping the native app to see if the Native Intercom is enabled.  If, so handleNativeIntercomEnabled will be called.
    sendRNMessage({
      status: REACT_NATIVE_STATUS.INTERCOM_ENABLED,
    })
  }, [])

  useEffect(() => {
    // This effect is for an edge case that happens when a user deletes their account on the mobile side and goes on the web app.  The web app will log you out and redirect you to /auth with a page refesh. I can't call intercomLogout since the page refreshes it resets the hook and isLoggedIn is set to false.  So, if the user is not logged in and the Intercom cookie session is present, we delete it.  This is not required, the app still works without this but feel like it might cause issues in the future.
    if (isNativeIntercomEnabled) {
      return
    }
    if (!isLoggedIn) {
      const intercomCookie = document.cookie
        .split(';')
        .map((cookie) => cookie.trim())
        .find((cookie) => {
          const [key] = cookie.split('=')
          if (key === `intercom-session-${process.env.REACT_APP_INTERCOM_APP_ID}`) {
            return true
          }
        })
      if (intercomCookie) {
        document.cookie = `${intercomCookie}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
      }
    }
  }, [isLoggedIn])

  useEffect(() => {
    // We default to initializing Intercom web SDK.  If Intercom is enabled on native side, we terminate the web sdk.
    if (isNativeIntercomEnabled) {
      intercomLogout()
      return
    }

    async function setupIntercom(user: User) {
      const userPayload = {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID as string,
        user_id: user.id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        user_hash: user.intercomWebHash,
        // this disables the chat from showing by default, so we can launch the chat programatically.
        hide_default_launcher: true,
        created_at: user.createdAt._seconds,
      }
      if (!isLoggedIn) {
        // initialize Intercom
        Intercom(userPayload)
        setIsLoggedIn(true)
      } else {
        if (!intercomUser) {
          return
        }
        const hasIntercomUserChanged = handleHasIntercomUserChanged({
          intercomUser,
          updatedUser: user,
        })
        // We update Intercom if any of the attributes change.
        if (hasIntercomUserChanged) {
          update({
            email: userPayload.email,
            created_at: user.createdAt._seconds,
            name: userPayload.name,
            user_id: user.id,
          })
        }
        // We update Intercom if no attributes have changed and the url has changed.
        if (!hasIntercomUserChanged && prevLocation && location.pathname !== prevLocation) {
          update({
            user_id: user.id,
            last_request_at: Math.floor(Date.now() / 1000),
          })
        }
      }
      setIntercomUser(user)
      setPrevLocation(location.pathname)
    }
    if (user) {
      setupIntercom(user)
    }
  }, [user, intercomUser, isLoggedIn, location.pathname, prevLocation, isNativeIntercomEnabled])

  useEffect(() => {
    if (!isNativeIntercomEnabled) {
      return
    }

    if (user && intercomUser) {
      // when the user updates we want to keep it in sync with the native side.
      const hasUserChanged = handleHasIntercomUserChanged({
        intercomUser,
        updatedUser: user,
      })

      if (hasUserChanged) {
        sendRNMessage({
          status: INTERCOM_UPDATE,
          user,
        })
      }
    }
    setIntercomUser(user)
  }, [user, intercomUser, isNativeIntercomEnabled])

  const intercomLogout = () => {
    if (!isLoggedIn) {
      return
    }
    setIsLoggedIn(false)
    shutdown()
  }

  const intercomOpen = () => {
    if (isNativeIntercomEnabled) {
      sendRNMessage({
        status: INTERCOM_OPEN,
      })
      return
    }
    if (!isLoggedIn) {
      return
    }
    show()
  }

  const handleIsNativeIntercomEnabled = () => {
    if (!isNativeIntercomEnabled) {
      setIsNativeIntercomEnabled(true)
    }
  }

  const handleHasIntercomUserChanged = ({
    intercomUser,
    updatedUser,
  }: {
    intercomUser: User
    updatedUser: User
  }) => {
    if (
      intercomUser.id !== updatedUser.id ||
      intercomUser.email !== updatedUser.email ||
      intercomUser.firstName !== updatedUser.firstName ||
      intercomUser.lastName !== updatedUser.lastName ||
      intercomUser.createdAt._seconds !== updatedUser.createdAt._seconds
    ) {
      return true
    }
    return false
  }
  return { intercomLogout, intercomOpen, handleIsNativeIntercomEnabled }
}

export default useIntercom
