import React, { useEffect, useState } from 'react'
import {
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { useQuery } from 'react-query'
import toast from 'react-hot-toast'
import * as campaignService from '../../Services/campaignService'
import * as adminService from '../../Services/adminService'
import { PLAYBACK_GAME_CATEGORIES_MAPPING } from '../../../../constants'
import CampaignOfferEditor from './CampaignOfferEditor'

const CampaignEditor = () => {
  const [tempCampaignId, setTempCampaignId] = useState('')
  const [appMetadataDocId, setAppMetadataDocId] = useState('')
  const [campaignId, setCampaignId] = useState('')
  const [jsonData, setJsonData] = useState('')
  const [offerId, setOfferId] = useState('')
  const [showRichTextPreview, setShowRichTextPreview] = useState(false)

  let jsonObj = null
  try {
    jsonObj = JSON.parse(jsonData)
  } catch (e) {
    // We don't care about the errors here, usually temp edit errors
  }

  const createMode = !campaignId || !jsonObj || campaignId !== jsonObj.id
  const convertRawDataToEditableFormat = (firestoreData) => {
    const apiData = { ...firestoreData }
    delete apiData.createdAt
    delete apiData.updatedAt
    if (!apiData.userTargeting) {
      apiData.userTargeting = {
        conditions: [],
      }
    }

    return apiData
  }

  const getEditableSortedJsonStr = (editableObj) => {
    // Sort these important properties to the top, and make everything else alphabetical
    const keyOrder = ['publisherInternalId', 'id', 'gameName', 'unifiedAppId']
    for (const key of Object.keys(editableObj).sort()) {
      if (keyOrder.includes(key)) {
        continue
      }

      keyOrder.push(key)
    }
    const objWithSortedKeys = keyOrder.reduce((acc, key) => {
      acc[key] = editableObj[key]
      return acc
    }, {})
    return JSON.stringify(objWithSortedKeys, null, 2)
  }

  const onNewOfferCreation = (newOffer) => {
    setOfferId(newOffer.docId)
  }

  const getCampaign = async () => {
    if (!campaignId) {
      return null
    }

    const campaign = await campaignService.getCampaign(campaignId)
    if (campaign) {
      const editableObj = convertRawDataToEditableFormat(campaign)
      const jsonString = getEditableSortedJsonStr(editableObj)

      if (editableObj.offerIds && editableObj.offerIds.length > 0) {
        const pathTokens = editableObj.offerIds[0].split('/')
        const newOfferId = pathTokens[pathTokens.length - 1]
        setOfferId(newOfferId)
      }

      setJsonData(jsonString)
    } else {
      setJsonData('')
    }

    return campaign
  }

  const campaignQuery = useQuery({ queryKey: ['campaign'], queryFn: getCampaign, enabled: false })
  useEffect(() => {
    campaignQuery.refetch()
  }, [campaignId])

  const handleCreateCampaign = async () => {
    if (!jsonObj) {
      toast.error("Invalid JSON format, can't create campaign")
      return
    }

    try {
      await campaignService.createCampaign(jsonObj)
      toast.success('Campaign created successfully')
    } catch (err) {
      console.error(err)
      toast.error('Failed to create campaign')
      return
    }

    setTempCampaignId(jsonObj.id)
    setCampaignId(jsonObj.id)
  }

  const handleUpdateCampaign = async () => {
    if (!jsonObj) {
      toast.error("Invalid JSON format, can't update campaign")
      return
    }

    try {
      await campaignService.patchCampaign(jsonObj.id, jsonObj)
      toast.success('Campaign updated successfully')
    } catch (err) {
      console.error(err)
      toast.error('Failed to update campaign')
    }
  }

  const handleDeleteCampaign = async () => {
    if (!jsonObj) {
      toast.error("Invalid JSON format, can't update campaign")
      return
    }

    const isConfirmed = window.confirm('Are you sure you want to delete this campaign?')
    if (!isConfirmed) {
      return
    }
    try {
      await campaignService.deleteCampaign(jsonObj.id)
      toast.success('Campaign deleted successfully')
    } catch (err) {
      console.error(err)
      toast.error('Failed to delete campaign')
    }
  }

  const handleSetCampaignId = () => {
    if (tempCampaignId === campaignId) {
      campaignQuery.refetch()
      return
    }

    setCampaignId(tempCampaignId)
  }

  const pullAppMetadata = async () => {
    if (jsonObj === null) {
      toast.error('You must fix the JSON format before pulling App Metadata')
      return
    }

    let appMetadata = null
    try {
      appMetadata = await adminService.getAppMetadata(appMetadataDocId)
    } catch (err) {
      console.error(err)
      toast.error('Failed to fetch app metadata')
      return
    }

    let appMetadatasForGame = null
    try {
      appMetadatasForGame = await adminService.getAppMetadatas(appMetadata.unified_app_id)
    } catch (err) {
      console.error(err)
      toast.error('Failed to fetch app metadatas for game')
      return
    }

    if (!appMetadata.feature_graphic) {
      const metadataWithFeaturedGraphic = appMetadatasForGame.find(
        (metadata) => metadata.feature_graphic
      )

      if (metadataWithFeaturedGraphic) {
        appMetadata.feature_graphic = metadataWithFeaturedGraphic.feature_graphic
      }
    }

    const newJsonObj = { ...jsonObj }
    newJsonObj.appDescription = appMetadata.description.replaceAll('\n', '<br>')
    newJsonObj.appRating = appMetadata.rating
    newJsonObj.gameName = appMetadata.name
    newJsonObj.featuredGraphic = appMetadata.feature_graphic
    newJsonObj.unifiedAppId = appMetadata.unified_app_id
    newJsonObj.categories = computeGameCategories(appMetadatasForGame)
    newJsonObj.screenshots = appMetadata.screenshot_urls
    newJsonObj.iconUrl = appMetadata.icon_url
    newJsonObj.storeLink = appMetadata.url

    const jsonString = getEditableSortedJsonStr(newJsonObj)
    setJsonData(jsonString)
  }

  /** Compute the game categories based on the categories found from the AppMetadata games */
  const computeGameCategories = (appMetadatas) => {
    const gameCategories = PLAYBACK_GAME_CATEGORIES_MAPPING.reduce((acc, currentValue) => {
      appMetadatas.forEach((appMetadata) => {
        if (appMetadata.categories.some((value) => currentValue.categories.includes(value))) {
          acc.add(currentValue.playbackCategory)
        }
      })
      return acc
    }, new Set())

    return Array.from(gameCategories)
  }

  const getOfferDocIdFromPath = (offerPath) => {
    if (!offerPath) {
      return null
    }
    const pathTokens = offerPath.split('/')
    return pathTokens[pathTokens.length - 1]
  }

  return (
    <Box padding={3} display="flex" flexDirection="column" gap={2}>
      <Box display="flex" gap={1}>
        <TextField
          label="Campaign ID to Copy"
          variant="outlined"
          value={tempCampaignId}
          onChange={(e) => setTempCampaignId(e.target.value)}
          sx={styles.textField}
          inputProps={styles.textFieldInputProps}
          InputLabelProps={styles.textFieldInputProps}
          size="small"
        />
        <Button variant="contained" onClick={handleSetCampaignId}>
          {campaignQuery.isFetching ? 'Fetching...' : 'Fetch Campaign'}
        </Button>
      </Box>
      <Box display="flex" gap={1}>
        <TextField
          label="App Metadata Doc Id"
          variant="outlined"
          value={appMetadataDocId}
          onChange={(e) => setAppMetadataDocId(e.target.value)}
          sx={styles.textField}
          inputProps={styles.textFieldInputProps}
          InputLabelProps={styles.textFieldInputProps}
          size="small"
        />
        <Button variant="contained" onClick={pullAppMetadata}>
          Pull App Metadata
        </Button>
      </Box>
      <Box display="flex" gap={1}>
        <TextField
          label="JSON Data"
          variant="outlined"
          multiline
          minRows={10}
          maxRows={20}
          value={jsonData}
          onChange={(e) => setJsonData(e.target.value)}
          fullWidth
          color={jsonObj === null ? 'error' : 'primary'}
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '0.875rem',
              lineHeight: '1.4',
            },
          }}
        />

        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="body2">id length: {jsonObj ? jsonObj.id.length : ''}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={showRichTextPreview}
                onChange={(e) => setShowRichTextPreview(e.target.checked)}
              />
            }
            label={<Typography variant="body2">Show Rich Text Preview</Typography>}
          />
        </Box>
      </Box>
      {showRichTextPreview && jsonObj && (
        <Typography
          variant="body2"
          sx={styles.richTextPreview}
          dangerouslySetInnerHTML={{
            __html: jsonObj.appDescription,
          }}
        />
      )}
      <Box display="flex" gap={1}>
        {createMode ? (
          <Button variant="contained" color="primary" onClick={handleCreateCampaign}>
            Create Campaign
          </Button>
        ) : (
          <>
            <Button variant="contained" color="secondary" onClick={handleUpdateCampaign}>
              Update Campaign
            </Button>
            <Button variant="contained" color="error" onClick={handleDeleteCampaign}>
              Delete Campaign
            </Button>
          </>
        )}
      </Box>
      <FormControl fullWidth sx={{ marginTop: 2 }}>
        <InputLabel id="offer-id-label">Offer ID</InputLabel>
        <Select
          labelId="offer-id-label"
          value={offerId}
          label="Offer ID"
          onChange={(e) => setOfferId(e.target.value)}
          inputProps={styles.textFieldInputProps}
          size="small"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {jsonObj &&
            jsonObj.offerIds.map((offerId) => (
              <MenuItem key={offerId} value={getOfferDocIdFromPath(offerId)}>
                {getOfferDocIdFromPath(offerId)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Input
        placeholder="Enter custom Offer ID"
        value={offerId}
        onChange={(e) => setOfferId(e.target.value)}
        sx={{ marginTop: 1 }}
        inputProps={styles.textFieldInputProps}
        size="small"
      />

      <CampaignOfferEditor offerId={offerId} onNewOfferCreation={onNewOfferCreation} />
    </Box>
  )
}

const styles = {
  textField: {
    width: 300,
  },
  textFieldInputProps: {
    style: {
      fontSize: '1.0rem',
    },
  },
  richTextPreview: {
    width: '50%',
  },
}

export default CampaignEditor
