import { Typography } from '@mui/material'

import './index.css'

const EarnGiftCards = ({ isBalance = false }) => {
  return (
    <Typography variant="h4" sx={styles.text}>
      Earn <img src="/images/pig.png" /> & <img src="/images/coin.png" /> by making purchases in
      Featured Game offers.
      <br />
      <br />
      Earn <img src="/images/pig.png" /> & <img src="/images/coin.png" /> by completing tasks in
      Featured Game offers.
      <br />
      <br />
      Earn <img src="/images/coin.png" /> for every non-offer game purchase.
      <br />
      The base rate is <strong>$1 USD spent</strong> ={' '}
      <div className={`valueGradientWrap earnGiftCardsValue ${isBalance ? 'balance' : ''}`}>
        <Typography variant="body2" sx={styles.noRecieptsValue} className="priceText coin">
          100 <img src="/images/coin.png" />
        </Typography>
      </div>
    </Typography>
  )
}

const styles = {
  text: {
    fontWeight: 400,
    marginTop: '8px',
    color: '#000000',
  },
  noRecieptsValue: {
    fontWeight: 700,
  },
}

export default EarnGiftCards
