import { axiosBackendInstance } from '../../../axios'

export const getCampaign = async (campaignId) => {
  return axiosBackendInstance.get(`/api/v1/campaigns/${campaignId}`)
}

export const createCampaign = async (campaignData) => {
  return axiosBackendInstance.post(`/api/v1/campaigns`, campaignData)
}

export const patchCampaign = async (campaignId, campaignData) => {
  const newData = { ...campaignData }
  delete newData.id
  delete newData.publisherInternalId

  return axiosBackendInstance.patch(`/api/v1/campaigns/${campaignId}`, newData)
}

export const deleteCampaign = async (campaignId) => {
  return axiosBackendInstance.delete(`/api/v1/campaigns/${campaignId}`)
}

export const getCampaignOffer = async (offerId) => {
  return axiosBackendInstance.get(`/api/v1/campaigns/offer/${offerId}`)
}

export const createCampaignOffer = async (offerData) => {
  const newData = { ...offerData }
  delete newData.id
  delete newData.playtimeRewards
  return axiosBackendInstance.post(`/api/v1/campaigns/offer`, newData)
}

export const patchCampaignOffer = async (offerId, offerData) => {
  const newData = { ...offerData }
  delete newData.id
  delete newData.playtimeRewards
  return axiosBackendInstance.patch(`/api/v1/campaigns/offer/${offerId}`, newData)
}

export const deleteCampaignOffer = async (offerId) => {
  return axiosBackendInstance.delete(`/api/v1/campaigns/offer/${offerId}`)
}
