import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { FreeMode, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { DialogContent } from '@mui/material'
import { Box } from '@mui/system'
import useCardsSlider from '../../../hooks/useCardsSlider'
import { BackArrow as ArrowIcon } from '../../../utils/icons'
import { reorderGiftCards, sendGA4Event } from '../../../utils/utils'
import { ANALYTICS_EVENT } from '../../../constants'
import Modal from '../../../components/modal'
import BottomButton from '../../../components/common/BottomButton'
import GiftCard from './GiftCard'

function InfoModal({ showModal, toggleModal }) {
  return (
    <Modal
      showModal={showModal}
      hideModal={toggleModal}
      closeButton={true}
      title="First redemption must be $25 or more"
      isCustomText={true}
      titleVariant="title"
      text={
        <DialogContent sx={styles.dialogContent}>
          <Box sx={styles.text}>
            To prevent abuse and mitigate the cost of identity verification there is a $25 minimum
            threshold required for the first redemption.
          </Box>
          <Box sx={styles.text}>
            After your first redemption all gift card amounts will be available.
          </Box>
          <BottomButton style={styles.button} handleClick={toggleModal}>
            Okay
          </BottomButton>
        </DialogContent>
      }
    />
  )
}

const GiftCardsRow = ({ giftCard, minRedeem }) => {
  const [denominations, setDenominations] = useState([])

  useEffect(() => {
    setDenominations(reorderGiftCards(giftCard?.denomination, minRedeem))
  }, [])

  const swiper = useCardsSlider(denominations?.length)

  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const handleSwiperClick = (swiper, { target }) => {
    const denomination = target.closest('.giftCardListCard')?.dataset?.denomination
    if (denomination) {
      if (denomination < minRedeem) {
        toggleModal()
      } else {
        sendGA4Event(ANALYTICS_EVENT.SELECT_ITEM.GIFT_CARD, {
          item_id: `${_.snakeCase(giftCard.name)}_${denomination}`,
          item_brand: giftCard.name,
          price: denomination,
        })

        navigate(`/rewards/${giftCard.id}/${denomination}`)
      }
    }
  }

  const sliderSettings = {
    className: 'gamesListOffersRowSlider',
    freeMode: true,
    modules: [FreeMode, Navigation],
    spaceBetween: 20,
    slidesPerView: 1,
    navigation: true,
    width: 216,
    ref: swiper.positionRef,
    onSwiper: swiper.setSwiper,
    onClick: handleSwiperClick,
    onSlideChange: swiper.changeButtonStates,
  }

  if (!denominations?.length) {
    return null
  }

  return (
    <>
      {isOpen && <InfoModal showModal={isOpen} toggleModal={toggleModal} />}
      <Typography variant="inherit" sx={styles.title}>
        {giftCard.name}
      </Typography>
      <div ref={swiper.wrapperRef} className="giftCardsRow">
        <div
          className={`gamesListOffersRowArrow ${!swiper.isPrevEnabled ? 'disabled' : ''} prev`}
          onClick={() => swiper.handleClickArrow(false)}
        >
          <ArrowIcon />
        </div>
        <Swiper {...sliderSettings}>
          {denominations.map((denomination, index) => (
            <SwiperSlide key={index}>
              <GiftCard
                giftCard={giftCard}
                denomination={denomination.value}
                discount={denomination.discount}
                minRedeem={minRedeem}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={`gamesListOffersRowArrow ${!swiper.isNextEnabled ? 'disabled' : ''} next`}
          onClick={swiper.handleClickArrow}
        >
          <ArrowIcon />
        </div>
      </div>
    </>
  )
}

const styles = {
  title: {
    marginBottom: '12px',
    paddingLeft: '20px',
  },
  button: {
    background: '#FB923C',
    color: 'white',
    '&:hover': {
      background: '#FB923C',
    },
  },
  text: {
    weight: 400,
    size: 14,
    textAlign: 'center',
    color: '#52525B',
    padding: '15px',
    paddingTop: '10px',
  },
}

export default GiftCardsRow
