import React, { useContext, useEffect, useRef } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Container } from '@mui/material'
import { ref, onChildAdded } from 'firebase/database'
import { useNavigate } from 'react-router-dom'

import { captureEvent } from '@sentry/react'
import { db } from '../../config/firebaseConfig'
import AuthContext from '../../context/AuthContext'
import LoaderContext from '../../context/LoaderContext'
import HistoryContext from '../../context/HistoryContext'
import Layout from '../../components/layout/Games'
import { getUserReferralRewards } from '../../services/referalService'
import {
  getUserUnclaimedRewards,
  getFeaturedGameOffers,
  getSpecialRewards,
} from '../../services/userRewardsService'
import {
  CLAIM_SPECIAL_REWARD_REASON,
  ERRORS,
  GAMES_PAGE_QUEST_TYPE,
  OFFER_TYPE,
  ONBOARDING_STEP,
  REACT_NATIVE_STATUS,
} from '../../constants'
import { handleError, sendRNMessage, showTOSPromptForUser } from '../../utils/utils'
import { getQuestsForGamesPage, refreshUserQuests } from '../../services/questsService'
import SlideLayout from '../../components/layout/Games/Slide'
import { getPiggyChestConfig } from '../../services/piggyBankChestService'
import useTOSPromptConfig from '../../hooks/useTOSPromptConfig'
import Content from './Content'
import ReferFriendAlert from './components/Alerts/ReferFriend'
import SpecialRewardAlert from './components/Alerts/SpecialReward'
import QuestAlert from './components/Alerts/Quest'
import PiggyBankChest from './components/Alerts/PiggyBankChest'

export const PREV_ROUTES = ['/library', '/rewards', '/quests']

const GamesList = () => {
  const { user, updateUserData } = useContext(AuthContext)
  const { previousPage } = useContext(HistoryContext)

  const notificationsRef = useRef(null)

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)

  const { config: TOSPromptConfig } = useTOSPromptConfig()
  useEffect(() => {
    if (
      user &&
      window.ReactNativeWebView &&
      showTOSPromptForUser(user, TOSPromptConfig?.updatedAt)
    ) {
      navigate('/accept-tos')
      hideLoader()
    }
  }, [])

  useEffect(() => {
    const offerActionsRef = ref(db, `offer_actions/${user.id}`)
    onChildAdded(offerActionsRef, () => {
      queryClient.invalidateQueries({
        queryKey: 'featuredGameOffers',
      })
    })
  }, [])

  const { data: config, isLoading: loadingPiggyChestDropRates } = useQuery(
    'piggyChestDropRates',
    () => getPiggyChestConfig(),
    { onError: handleError }
  )

  const { data: specialRewards, isLoading: loadingSpecialRewards } = useQuery(
    'specialRewards',
    () =>
      getSpecialRewards(null, [
        CLAIM_SPECIAL_REWARD_REASON.INSTALL_GAME,
        CLAIM_SPECIAL_REWARD_REASON.PLAY_GAME,
        CLAIM_SPECIAL_REWARD_REASON.PLAYTIME,
        CLAIM_SPECIAL_REWARD_REASON.MMP_EVENT,
        CLAIM_SPECIAL_REWARD_REASON.PURCHASE,
        CLAIM_SPECIAL_REWARD_REASON.PURCHASE_AMOUNT,
      ]),
    { onError: handleError }
  )

  const { data: unclaimedRewards, isLoading: loadingUnclaimedRewards } = useQuery(
    'unclaimedRewards',
    () => getUserUnclaimedRewards(user.id),
    { onError: handleError }
  )

  const { data: referralRewards, isLoading: loadingReferralRewards } = useQuery(
    'referralRewards',
    () => getUserReferralRewards('unclaimed'),
    { onError: handleError }
  )

  const { data: offers, isLoading: loadingFeaturedGameOffers } = useQuery(
    'featuredGameOffers',
    () => getFeaturedGameOffers(user.id),
    {
      onError: handleError,
      retry: (failureCount, error) => {
        // Handle and report timeout error
        if (error.message.includes('timeout')) {
          captureEvent({
            message: ERRORS.FEATURED_GAME_TIMEOUT_ERROR,
            level: 'error',
            extra: { err: error },
          })

          return failureCount < 1 // Retry only 1 time
        }
        return failureCount < 3 // Retry up to 3 times
      },
    }
  )

  useEffect(() => {
    if (
      offers?.activatedRow.find(
        (offer) => offer.offerType === OFFER_TYPE.REWARDED_PLAYTIME || offer.batchPlaytimeConfig
      )
    ) {
      sendRNMessage({
        status: REACT_NATIVE_STATUS.START_PLAYTIME_TRACKING_IN_FOREGROUND,
      })
    }
  }, [offers?.activatedRow])

  const { isLoading: loadingRefreshQuests } = useQuery(
    'assignActiveQuests',
    () => refreshUserQuests(user),
    {
      onSuccess: (data) => data && updateUserData(data.updatedUser),
      onError: handleError,
    }
  )

  const { data: quests, isLoading: loadingQuests } = useQuery('quests', getQuestsForGamesPage, {
    onError: handleError,
  })

  const isFetching =
    loadingPiggyChestDropRates ||
    loadingRefreshQuests ||
    loadingUnclaimedRewards ||
    loadingSpecialRewards ||
    loadingReferralRewards ||
    loadingQuests ||
    loadingFeaturedGameOffers

  useEffect(() => {
    isFetching ? showLoader() : hideLoader()
  }, [isFetching])

  return (
    <Layout isLoading={isLoading}>
      {user && (!user.onboardingStep || user.onboardingStep === ONBOARDING_STEP.COMPLETE) && (
        <Container maxWidth="sm" className="gamesListNotifications" ref={notificationsRef}>
          {referralRewards?.map((reward) => (
            <ReferFriendAlert key={reward.id} reward={reward} />
          ))}
          {specialRewards?.map((reward) => (
            <SpecialRewardAlert key={reward.id} reward={reward} />
          ))}
          {quests &&
            Object.entries(GAMES_PAGE_QUEST_TYPE).map(([, value]) =>
              quests[value]?.map((quest) => (
                <QuestAlert quest={quest} key={quest.id} type={value} />
              ))
            )}
          {user.isChestAvailableForPurchase && <PiggyBankChest config={config} />}
        </Container>
      )}

      <SlideLayout
        prevRoutes={PREV_ROUTES}
        direction="right"
        swipeIn={!isLoading}
        isSwiping={previousPage?.startsWith('/games/')}
      >
        <Content
          unclaimedRewards={unclaimedRewards}
          isLoading={isLoading}
          offers={offers}
          previousPage={previousPage}
          notificationsRef={notificationsRef}
        />
      </SlideLayout>
    </Layout>
  )
}

export default GamesList
