import React, { useMemo } from 'react'

//constants
import { CLAIM_SPECIAL_REWARD_REASON } from 'constants/index'
//components
import GenericMMPPurchaseTask from './GenericMMPPurchaseTask'
import GenericPlaytimeTask from './GenericPlaytimeTask'
//types
import { GenericPlaytimeMMPTaskProps } from 'types'
//utils
import {
  isLockedGenericTask,
  checkIfPlaytimeTaskCompleted,
  isExpiredGenericTask,
  isGenericTaskHidden,
  isGenericTaskNotStarted,
} from 'utils/genericOffer'

const GenericPlaytimeMMPTask = ({
  isInstalled,
  batchPlaytimeConfig,
  batches,
  playtimeBatchInfo,
  activeIndex,
  allPlaytimeTasksCompleted,
  mmpEventTasks,
  onButtonClick,
  onRefresh,
}: GenericPlaytimeMMPTaskProps) => {
  const unlockedMMPTasks = useMemo(
    () =>
      mmpEventTasks.filter(
        (task) =>
          !isGenericTaskHidden(task, isInstalled) && !isLockedGenericTask(task, mmpEventTasks)
      ),
    [mmpEventTasks, isLockedGenericTask, isGenericTaskHidden, isInstalled]
  )
  const lockedMMPTasks = useMemo(
    () =>
      mmpEventTasks.filter(
        (task) =>
          !isGenericTaskHidden(task, isInstalled) &&
          (isLockedGenericTask(task, mmpEventTasks) || !isInstalled)
      ),
    [mmpEventTasks, isLockedGenericTask, isInstalled, isGenericTaskHidden]
  )
  const isPlaytimeTaskCompleted = useMemo(
    () => checkIfPlaytimeTaskCompleted(batchPlaytimeConfig, playtimeBatchInfo, activeIndex),
    [batchPlaytimeConfig, playtimeBatchInfo, activeIndex]
  )
  const expiredShownMMPTasks = useMemo(
    () => mmpEventTasks.filter((task) => isExpiredGenericTask(task) && !task.hideAfterExpire),
    [mmpEventTasks, mmpEventTasks, isExpiredGenericTask]
  )

  const firstActionableGenericTaskIndex = useMemo(
    () =>
      unlockedMMPTasks.findIndex(
        (task) => !task.completed && !isGenericTaskNotStarted(task) && !isExpiredGenericTask(task)
      ),
    [unlockedMMPTasks]
  )

  const isPlaytimeBatchTaskDisplayed = useMemo(
    () =>
      batchPlaytimeConfig &&
      !allPlaytimeTasksCompleted &&
      activeIndex > -1 &&
      !isPlaytimeTaskCompleted,
    [batchPlaytimeConfig, allPlaytimeTasksCompleted, activeIndex, isPlaytimeTaskCompleted]
  )

  // If the playtime batch task is not displayed, we should show the first actionable task at the top of the tasks list
  const sortedGenericTasks = useMemo(() => {
    if (isPlaytimeBatchTaskDisplayed) {
      return unlockedMMPTasks
    }
    const unlockedMMPTasksCopy = unlockedMMPTasks.slice()
    const [firstActionableTask] = unlockedMMPTasksCopy.splice(firstActionableGenericTaskIndex, 1)
    return firstActionableTask ? [firstActionableTask, ...unlockedMMPTasksCopy] : unlockedMMPTasks
  }, [unlockedMMPTasks, firstActionableGenericTaskIndex, isPlaytimeBatchTaskDisplayed])

  return (
    <>
      {/* Unlocked Generic Playtime Tasks*/}
      {isPlaytimeBatchTaskDisplayed && (
        <GenericPlaytimeTask
          key={`playtime-${activeIndex}`}
          index={activeIndex}
          activeIndex={activeIndex}
          batchPlaytimeConfig={batchPlaytimeConfig}
          playtimeBatchInfo={playtimeBatchInfo}
          onRefresh={onRefresh}
          buttonText="Play"
          onButtonClick={() => onButtonClick(CLAIM_SPECIAL_REWARD_REASON.PLAYTIME)}
          isInstalled={isInstalled}
        />
      )}
      {/* Unlocked Generic MMP Tasks*/}
      {isInstalled &&
        sortedGenericTasks.map((task, index) => {
          return (
            <GenericMMPPurchaseTask
              key={`mmpevent-${task.taskId}-${index}`}
              isInstalled={isInstalled}
              allMMPTasks={mmpEventTasks}
              mmpTask={task}
              buttonText={index === 0 && isPlaytimeTaskCompleted ? 'Play' : undefined}
              onButtonClick={() =>
                onButtonClick(CLAIM_SPECIAL_REWARD_REASON.MMP_EVENT, task.taskDescription)
              }
              onRefresh={onRefresh}
            />
          )
        })}
      {/* Locked Generic Playtime Tasks*/}
      {batchPlaytimeConfig &&
        !allPlaytimeTasksCompleted &&
        batches.map((_, index) => {
          return (
            index > activeIndex &&
            index <= activeIndex + 1 && (
              <GenericPlaytimeTask
                key={`playtime-${index}`}
                index={index}
                activeIndex={activeIndex}
                batchPlaytimeConfig={batchPlaytimeConfig}
                playtimeBatchInfo={playtimeBatchInfo}
                onRefresh={onRefresh}
                isInstalled={isInstalled}
              />
            )
          )
        })}
      {/* Locked Generic MMP Tasks*/}
      {lockedMMPTasks?.map((task, index) => {
        return (
          <GenericMMPPurchaseTask
            key={`mmpevent-${task.taskId}-${index}`}
            isInstalled={isInstalled}
            allMMPTasks={mmpEventTasks}
            mmpTask={task}
            onRefresh={onRefresh}
          />
        )
      })}
      {/* Expired but shown Generic MMP Tasks*/}
      {expiredShownMMPTasks?.map((task, index) => {
        return (
          <GenericMMPPurchaseTask
            key={`mmpevent-expired-${task.taskId}-${index}`}
            isInstalled={isInstalled}
            allMMPTasks={mmpEventTasks}
            mmpTask={task}
            onRefresh={onRefresh}
          />
        )
      })}
    </>
  )
}

export default GenericPlaytimeMMPTask
