import React, { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'

import Layout from '../../components/layout/Games'
import { getAllGiftCards } from '../../services/giftCardsService'
import LoaderContext from '../../context/LoaderContext'
import { handleError } from '../../utils/utils'
import SlideLayout from '../../components/layout/Games/Slide'
import Content from './Content'

import './index.css'

const PREV_ROUTES = ['/library', '/games', '/quests']

const GiftCardsList = () => {
  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)

  const { data, isFetching } = useQuery('giftCards', getAllGiftCards, {
    refetchOnWindowFocus: false,
    onError: handleError,
  })

  useEffect(() => {
    isFetching ? showLoader() : hideLoader()
  }, [isFetching])

  return (
    <Layout isLoading={isLoading}>
      <SlideLayout prevRoutes={PREV_ROUTES} swipeIn={!isFetching}>
        <Content giftCards={data?.giftCards} minRedeem={data?.minRedeem} />
      </SlideLayout>
    </Layout>
  )
}

export default GiftCardsList
