import { AxiosResponse } from 'axios'

import { axiosBackendInstance } from '../axios'
import axiosWebInstance from '../axiosWebInstance'
import { User } from 'types'

export const getUserById = async (
  userId: string | undefined,
  isLogin = false
): Promise<AxiosResponse<User, Error>> => {
  const requestRandom = Math.random()
  return axiosWebInstance.get(
    `/api/v1/users/${userId}?requestRandom=${requestRandom}&isLogin=${isLogin}`
  )
}

export const getUserByEmail = async (email: string) => {
  return axiosBackendInstance.get(`/api/v1/users/email/${email}`)
}

export const removeUserAccount = async () => {
  return axiosBackendInstance.delete('/api/v1/users')
}

export const requestUserData = async () => {
  return axiosBackendInstance.get('/api/v1/users/data')
}

export const updateUser = async (userData: User) => {
  return axiosBackendInstance.put('/api/v1/users', userData)
}

export const getUserBanEndTime = async () => {
  return axiosBackendInstance.get(`/api/v1/users/ban-time`)
}

export const addSecondEmail = async (connectedId: string) => {
  return axiosBackendInstance.patch(`/api/v1/users/second-email/${connectedId}`)
}

export const getSecondEmails = async () => {
  return axiosBackendInstance.get('/api/v1/users/second-emails')
}

export const deleteSecondEmail = async (email: string) => {
  return axiosBackendInstance.delete('/api/v1/users/second-emails', { data: { email } })
}

export const sendDeviceData = async (deviceData: unknown, userId: string) => {
  return axiosBackendInstance.post(`/api/v1/users/${userId}/device-info`, deviceData)
}

export const sendEventAnalytic = async (eventData: unknown, token: string) => {
  return axiosBackendInstance.post('/api/v1/users/event-analytic', eventData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const sendUnauthorizedEventAnalytic = async (eventData: any) => {
  return await axiosBackendInstance.post('/api/v1/users/analytic', eventData)
}

export const initSignUpReminder = async (deviceData: unknown) => {
  return axiosBackendInstance.post('/api/v1/users/sign-up-reminder', deviceData)
}

export const sendAppBootTracking = async (params: unknown, userId: string) => {
  return axiosBackendInstance.post(`/api/v1/users/${userId}/app-boot`, params)
}

export const offSignUpReminder = async (deviceData: unknown) => {
  return axiosBackendInstance.patch('/api/v1/users/sign-up-reminder', deviceData)
}

export const getDeviceData = async (userId: string) => {
  return axiosBackendInstance.get(`/api/v1/users/${userId}/device-info`)
}

export const isIPAddressBlocked = async () => {
  return axiosWebInstance.get('/api/v1/users/ip-check')
}
